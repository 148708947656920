import { Me } from '../chatApi'
import React, { useState } from 'react'
import { Button } from './Buttons'
import Tune from '@mui/icons-material/Tune'
import { PromptDropdownSelect } from './PromptDropdownSelect'
import { Dialog } from './Dialog'

export type CustomSystemPromptDialogProps = {
  me: Me | null
  customSystemPrompt: string | null
  setCustomSystemPrompt: (customSystemPrompt: string | null) => void
}

export const CustomSystemPromptDialog = (
  props: CustomSystemPromptDialogProps,
) => {
  const [isOpen, setIsOpen] = useState(false)
  const [tempPrompt, setTempPrompt] = useState<string | null>(
    props.customSystemPrompt,
  )
  const alternativeSystemPrompts = props.me?.alternativeSystemPrompts || []

  const openDialog = () => {
    setTempPrompt(props.customSystemPrompt)
    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const savePrompt = () => {
    props.setCustomSystemPrompt(tempPrompt)
    setIsOpen(false)
  }

  const selectedPrompt = alternativeSystemPrompts.find(
    (item) => item.prompt === tempPrompt,
  )

  return (
    <>
      <Button onClick={openDialog}>
        <Tune />
        Sett systemprompt
      </Button>
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onClose={closeDialog}
          header={'Sett systemprompt'}
        >
          {alternativeSystemPrompts.length >= 2 && (
            <div className={'flex gap-2 mb-2 items-center'}>
              <p>Valg:</p>
              <div className={'flex gap-1'}>
                {alternativeSystemPrompts.map((item) => (
                  <Button
                    key={item.name}
                    size={'small'}
                    highlighted={
                      selectedPrompt && selectedPrompt.prompt === item.prompt
                    }
                    onClick={() => setTempPrompt(item.prompt)}
                  >
                    {item.name}
                  </Button>
                ))}
              </div>
            </div>
          )}
          <textarea
            rows={21}
            className={
              'w-full p-2 border-2 rounded bg-gray-50 dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md'
            }
            placeholder={'Skriv inn systemprompt her...'}
            value={tempPrompt || ''}
            onChange={(e) => setTempPrompt(e.target.value)}
          />
          <div className={'flex gap-2 justify-end'}>
            <Button onClick={closeDialog}>Avbryt</Button>
            <Button onClick={savePrompt} variant={'primary'}>
              Lagre
            </Button>
          </div>
        </Dialog>
      )}
    </>
  )
}
