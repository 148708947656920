import React, { useState } from 'react'
import { Info, ThumbUp } from '@mui/icons-material'
import { Button } from './Buttons'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import { Dialog } from './Dialog'

type SmileyIconProps = {
  grade: number
}
export const SmileyIcon = ({ grade }: SmileyIconProps) => {
  const smileyIcons = [
    <SentimentVeryDissatisfiedIcon className="text-red-500" />,
    <SentimentDissatisfiedIcon className="text-orange-500" />,
    <SentimentNeutralIcon className="text-yellow-500" />,
    <SentimentSatisfiedIcon className="text-green-500" />,
    <SentimentVerySatisfiedIcon className="text-blue-500" />,
  ]

  return <>{smileyIcons[grade - 1]}</>
}

type FeedbackProps = {
  onSubmit: (grade: number, comment: string) => Promise<void>
}
export const GiveFeedback = (props: FeedbackProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [grade, setGrade] = useState<number | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const commentTemplates = [
    'Svarte på andre ting enn spørsmålet',
    'Svar ikke tilpasset målgruppen',
    'Lenker videre til irrelevant innhold',
    'Lenker virker ikke',
    'For langt svar',
    'Generisk svar',
    'Veldig bra',
    'Ved uklare spørsmål er det uklart hva den faktisk svarer på (må tolke seg frem til et spm)',
  ]

  const openDialog = () => {
    setDialogOpen(true)
  }
  const closeDialog = () => {
    setDialogOpen(false)
  }
  const submitFeedback = async () => {
    setIsSubmitting(true)
    await props.onSubmit(grade || 0, comment)
    setIsSubmitting(false)
    closeDialog()
  }

  return (
    <>
      <Dialog
        header={'Gi tilbakemelding'}
        isOpen={dialogOpen}
        onClose={closeDialog}
      >
        <div>
          <p>Hvor fornøyd var du med svaret?</p>
          <div className="flex gap-2">
            {[1, 2, 3, 4, 5].map((n) => (
              <button
                key={n}
                onClick={() => setGrade(n)}
                className={`p-3 rounded-full ${grade === n ? 'border-2 border-blue-500 bg-gray-100 dark:bg-gray-600 dark:border-blue-300' : 'border-2 bg-gray-50 dark:bg-gray-800'} focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md dark:border-gray-700 dark:border-2 dark:bg-gray-700`}
              >
                <SmileyIcon grade={n} />
              </button>
            ))}
          </div>

          <p className={'mt-6'}>Kommentar</p>
          <div>
            <textarea
              className="border-2 border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md w-full dark:bg-gray-800 dark:text-white dark:border-gray-700"
              rows={3}
              value={comment}
              placeholder="Skriv eller trykk i listen under :)"
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className={'p-2'}>
            <h4 className={'text-sm'}>Kommentarforslag:</h4>
            <div className={'flex gap-1 flex-wrap'}>
              {commentTemplates.map((commentTemplate, index) => (
                <Button
                  key={index}
                  size={'small'}
                  onClick={() =>
                    setComment((current) => current + commentTemplate + '. ')
                  }
                >
                  {commentTemplate}
                </Button>
              ))}
            </div>
          </div>
          <div
            className={
              'mt-6 p-2 flex items-center gap-2 bg-gray-100 rounded-xl dark:bg-gray-600'
            }
          >
            <Info />
            <p>
              Ved å sende samtykker du til at Semantic Lab kan lese din
              tilbakemelding og se loggen fra denne samtalen
            </p>
          </div>
          <div className={'flex justify-between mt-2'}>
            <Button onClick={closeDialog}>Avbryt</Button>
            <div className={'flex gap-2 items-center'}>
              <Button onClick={submitFeedback} variant={'primary'}>
                Samtykk og send
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Button onClick={openDialog}>
        <ThumbUp />
        Gi tilbakemelding
      </Button>
    </>
  )
}
