import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'e6a4be39-923f-429c-b0d4-152a2bfdffb3',
  header: 'Mattilsynet',
  subheader: 'Trygge framtiden for mennesker, dyr og natur',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    isLight: false,
    bgColor: 'bg-fancy-wood',
    textColor: 'text-fancy-wood',
    bubbles: 'bg-green-600',
  },
  chatServer:
    (process.env.REACT_APP_CHAT_SERVER || 'https://ai.semanticlab.no') +
    '/api/v1/chatbots/mattilsynet',
}
export default config
