import React, { useState } from 'react'
import Markdown from 'react-markdown'
import { ExpandablePanel } from './Chat'

export type DocumentViewerProps = {
  documents: any[]
  highlightedDocumentIds: string[]
}
export const SourcesView = (props: DocumentViewerProps) => {
  const [showAll, setShowAll] = useState(true)
  return (
    <>
      {/*<h3 className={'font-bold uppercase text-gray-600'}>Kilder</h3>*/}
      <div className={'flex flex-col gap-2'}>
        {props.documents
          .filter(
            (doc, index) =>
              showAll ||
              props.highlightedDocumentIds.includes('' + doc.documentId),
          ) // Filter before mapping
          .map((doc, index) => (
            <ExpandablePanel
              variant={'quotation'}
              title={
                <div>
                  <div>
                    {doc.title}{' '}
                    <span className={'text-gray-500 text-sm dark:text-gray-400'}>
                      – {doc.sourceName}
                    </span>
                  </div>
                  <a
                    href={doc.source}
                    target="_blank"
                    rel="noreferrer"
                    className={'text-sm text-blue-700 dark:text-blue-300 no-underline font-normal'}
                  >
                    {doc.source}
                  </a>
                </div>
              }
            >
              <div className={'prose lg:prose prose-stone dark:prose-invert'}>
                <Markdown>{doc.content}</Markdown>
              </div>
            </ExpandablePanel>
          ))}
        {/*<CardLike onClick={() => setShowAll(!showAll)}>*/}
        {/*  <span className={'text-gray-500 uppercase text-sm'}>*/}
        {/*    {showAll ? 'Skjul øvrige kilder...' : 'Vis øvrige kilder...'}*/}
        {/*  </span>*/}
        {/*</CardLike>*/}
      </div>
    </>
  )
}
