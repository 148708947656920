import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'baa61850-fbea-4bd6-99c9-8f4edf689d6b',
  header: 'Felleskatalogen',
  subheader: 'Finn info om medisiner',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    isLight: false,
    bgColor: 'bg-blue-800',
    textColor: 'text-blue-800',
    bubbles: 'bg-green-600'
  },
  chatServer: (process.env.REACT_APP_CHAT_SERVER || 'https://ai.semanticlab.no') + '/api/v1/chatbots/felleskatalogen'
}
export default config
