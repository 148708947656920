import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button } from './Buttons'

type Choice = {
  value: number
  text: string | null
}

type Question = {
  type: 'single' | 'multiple' | 'string' | 'double'
  name: string
  text: string
  choices: Choice[]
}

const getInitialValueByType = (questionType: Question['type']) => {
  const typeValues = {
    single: null,
    multiple: [],
    string: '',
    double: null,
  }
  return typeValues[questionType]
}

type Answers = Record<string, any>

const autoFillAnswers = (answers: Answers): Answers => {
  const now = new Date()

  // Format helpers
  const formatTime = (date: Date) =>
    date.toLocaleTimeString('nb-NO', { hour: '2-digit', minute: '2-digit' })

  const formatDate = (date: Date) =>
    date.toLocaleDateString('nb-NO', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })

  const getDayNumber = (date: Date) => {
    const day = date.getDay()
    return day === 0 ? 7 : day // Convert Sunday from 0 to 7
  }

  // Fields to auto-fill
  const autoFilledFields = {
    s_11: getDayNumber(now), // Day of week
    s_2: formatDate(now), // Date
    s_9: formatTime(now), // Time
    b_2: [
      'Mandag',
      'Tirsdag',
      'Onsdag',
      'Torsdag',
      'Fredag',
      'Lørdag',
      'Søndag',
    ][now.getDay() - 1], // Day name
    b_3: formatDate(now), // Date
    b_4: formatTime(now), // Time
  }

  return {
    ...answers,
    ...autoFilledFields,
  }
}

type QuestionnaireProps = {
  fillQuestionnaire: (text: string) => Promise<any>
  submitQuestionnaire: (answers: any) => void
  fields: Question[]
}

const initializeAnswers = (fields: Question[]) =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: getInitialValueByType(field.type),
    }),
    {},
  )

export const Questionnaire = ({
  fields,
  fillQuestionnaire,
  submitQuestionnaire,
}: QuestionnaireProps) => {
  console.log('fields:', fields)
  const [answers, setAnswers] = useState(() => initializeAnswers(fields))
  const [text, setText] = useState('')

  useEffect(() => {
    console.log('answers:', answers)
  }, [answers])

  return (
    <div
      className={
        'overflow-y-auto flex-1 bg-gray-100  dark:bg-gray-800 dark:text-white rounded-t-3xl flex flex-col items-center w-full'
      }
    >
      <h1 className={'text-3xl'}>Lag statistikk</h1>
      <div className={'flex flex-col gap-4 p-4 md:max-w-4xl w-full'}>
        <textarea
          className={
            'p-4 bg-white border dark:bg-gray-800 dark:text-white dark:border-gray-700 rounded-lg w-full'
          }
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div>
          <Button
            onClick={async () => {
              const filled = await fillQuestionnaire(text)
              console.log(filled)
              setAnswers((prev) => {
                const next = JSON.parse(filled.filledQuestionnaire)
                console.log('prev', prev, 'next', next)
                const updatedAnswers = Object.keys(next).reduce(
                  (acc, key) => {
                    return {
                      ...acc,
                      [key]: next[key],
                    }
                  },
                  { ...prev },
                )
                const programmaticallyUpdatedAnswers =
                  autoFillAnswers(updatedAnswers)
                return programmaticallyUpdatedAnswers
              })
            }}
            variant={'primary'}
          >
            Fyll skjema
          </Button>
        </div>

        {fields
          .filter((f) => !f.name.includes('b_'))
          .map((field) => {
            return (
              <QuestionComponent
                key={field.name}
                question={field}
                currentAnswer={(answers as any)[field.name]}
                setAnswer={(answer: any) =>
                  setAnswers((current: any) => ({
                    ...current,
                    [field.name]: answer,
                  }))
                }
              />
            )
          })}

        <Button
          onClick={() => submitQuestionnaire(answers)}
          variant={'primary'}
        >
          Send inn svar
        </Button>
      </div>
    </div>
  )
}

const QuestionComponent = ({
  question,
  currentAnswer,
  setAnswer,
}: {
  question: Question
  currentAnswer: any
  setAnswer: (answer: any) => void
}) => {
  return (
    <div className="flex gap-2 items-center">
      <h2 className={'text'}>
        {question.text}{' '}
        <small className="text-gray-500">({question.name})</small>:
      </h2>
      {question.type === 'string' && (
        <input
          type={'text'}
          className={
            'border-2 border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md w-full dark:bg-gray-800 dark:text-white dark:border-gray-700'
          }
          value={currentAnswer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      )}
      {(question.type === 'multiple' || question.type === 'single') && (
        <Select
          className="flex-1"
          unstyled={true}
          classNames={{
            control: ({ isFocused }) =>
              `${isFocused ? 'border-primary-500' : 'border-gray-300'} border border-gray-300 rounded-md bg-white dark:bg-gray-800 dark:text-white dark:border-gray-700`,
            placeholder: () => 'text-gray-500 dark:text-gray-400',
            input: () => '',
            valueContainer: () => 'flex items-center gap-2 p-2',
            singleValue: () => 'text-gray-900 dark:text-white p-1 rounded',
            multiValue: () => 'bg-gray-200 dark:bg-gray-700  p-1 rounded',
            multiValueLabel: () => 'text-gray-900 dark:text-white',
            multiValueRemove: () =>
              'text-gray-900 dark:text-white hover:text-gray-500 dark:hover:text-gray-400',
            indicatorsContainer: () => 'flex items-center gap-2',
            clearIndicator: () =>
              'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300',
            indicatorSeparator: () => 'bg-gray-300 dark:bg-gray-700',
            dropdownIndicator: () =>
              'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300',
            menu: () => 'bg-white dark:bg-gray-700 p-1',
            groupHeading: () => 'text-gray-900 dark:text-white font-bold',
            option: ({ isFocused, isSelected }) =>
              `${isFocused ? 'bg-gray-100 dark:bg-gray-900' : ''} ${isSelected ? 'bg-gray-200 dark:bg-gray-700' : ''} text-gray-900 dark:text-white p-1 rounded cursor-pointer`,
            noOptionsMessage: () => 'text-gray-500 dark:text-gray-400',
          }}
          options={question.choices.map((choice) => ({
            value: choice.value,
            label: choice.text || 'Ingen valg',
          }))}
          value={
            question.type === 'multiple'
              ? question.choices
                  .filter((choice) => currentAnswer.includes(choice.value))
                  .map(
                    (c: any) =>
                      ({
                        label: (
                          <span>
                            {c.text}{' '}
                            <small className="text-gray-500">({c.value})</small>
                          </span>
                        ),
                        value: c.value,
                      }) as any,
                  )
              : question.choices
                  .filter((choice) => choice.value === currentAnswer)
                  .map((c) => ({
                    label: (
                      <span>
                        {c.text}{' '}
                        <small className="text-gray-500">({c.value})</small>
                      </span>
                    ),
                    value: c.value,
                  }))[0]
          }
          isMulti={question.type === 'multiple'}
          onChange={(selected) => {
            if (!selected) return
            if (question.type === 'multiple') {
              setAnswer((selected as any).map((s: any) => s.value))
            } else {
              setAnswer((selected as any).value)
            }
          }}
        />
      )}
    </div>
  )
}
