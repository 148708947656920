import React, { useEffect, useState } from 'react'
import './App.css'
import { useMsal } from '@azure/msal-react'
import { apiSearchScopeAction } from './authConfig'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import useCustomerConfig from './hooks/useCustomerConfig'
import AddCommentIcon from '@mui/icons-material/AddComment'
import { Chat } from './components/Chat'
import { FeedbackExplorer } from './components/FeedbackExplorer'
import { Message } from './types'
import { ChatInputBox } from './components/ChatInputBox'
import { GiveFeedback } from './components/GiveFeedback'
import { Button } from './components/Buttons'
import MenuIcon from '@mui/icons-material/Menu'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import {
  createNewChatbotThread,
  getMe,
  Me,
  postChatMsgToThreadAndStreamResponse,
  fillQuestionnaire,
  submitQuestionnaire,
  getQuestionnaireFields,
} from './chatApi'
import { CustomSystemPromptDialog } from './components/CustomSystemPromptDialog'
import { Sidebar, Tab } from './components/Sidebar'
import { Questionnaire } from './components/Questionnaire'
import ChatIcon from '@mui/icons-material/Chat'
import BarChartIcon from '@mui/icons-material/BarChart'

const getAccessTokenForSemanticApi = async (accounts: any, instance: any) => {
  const accessTokenRequest = {
    scopes: [apiSearchScopeAction],
    account: accounts[0],
  }
  try {
    const accessTokenResponse =
      await instance.acquireTokenSilent(accessTokenRequest)
    return accessTokenResponse.accessToken
  } catch (error) {
    console.log('Silent token acquisition fails. Acquiring token using popup')
    try {
      const accessTokenResponse =
        await instance.acquireTokenRedirect(accessTokenRequest)
      return accessTokenResponse.accessToken
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

const availableTabs: Tab[] = [
  { id: 'chat', label: 'Chat', icon: <ChatIcon /> },
  { id: 'statistikk', label: 'Statistikk', icon: <BarChartIcon /> },
]

const App = () => {
  const { instance, accounts } = useMsal()
  const [darkMode, setDarkMode] = useState(() => {
    const storedMode = localStorage.getItem('darkMode')
    if (storedMode !== null) {
      return storedMode === 'true'
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  })
  const customerConfig = useCustomerConfig()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [threadId, setThreadId] = useState<string | null>(null)
  const [conversation, setConversation] = useState<Message[]>([])
  const [botIsTyping, setBotIsTyping] = useState(false)
  const [activeTab, setActiveTab] = useState<string>('chat')
  const [customSystemPrompt, setCustomSystemPrompt] = useState<string | null>(
    null,
  )
  const [me, setMe] = useState<Me | null>(null)
  const [questionnaireFields, setQuestionnaireFields] = useState<any[] | null>(
    null,
  )

  const startNewConversationThread = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newThreadId = await createNewChatbotThread(
      token,
      customerConfig.chatServer,
    )
    setThreadId(newThreadId)
    setConversation([])
  }

  const fetchMe = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const me = await getMe(token, customerConfig.chatServer)
    if (me) {
      setMe(me)
      if (me.systemPrompt && customSystemPrompt === null) {
        setCustomSystemPrompt(me.systemPrompt)
      }
    }
  }

  const getQuestionnaireFieldsX = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const fields = await getQuestionnaireFields(
      token,
      customerConfig.chatServer,
    )
    setQuestionnaireFields(fields)
  }

  useEffect(() => {
    if (activeTab === 'statistikk' && questionnaireFields === null) {
      getQuestionnaireFieldsX()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (accounts.length > 0 && instance && threadId === null) {
      const timer = setTimeout(startNewConversationThread, 250)
      if (!me) fetchMe()

      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance])

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode
      localStorage.setItem('darkMode', newMode.toString())
      return newMode
    })
  }

  const onFeedbackSubmit = async (grade: number, comment: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else {
      const res = await fetch(customerConfig.chatServer + '/feedback', {
        method: 'POST',
        body: JSON.stringify({ grade, comment, conversation }),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
    }
  }

  const onSubmitChatMessage = async (messageText: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newConversation = [
      ...conversation,
      { role: 'user', text: messageText } as Message,
    ]
    setConversation(newConversation)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else if (!threadId) {
      console.error('No thread id')
      return
    } else {
      const responseStream = postChatMsgToThreadAndStreamResponse(
        token,
        customerConfig.chatServer,
        threadId,
        messageText,
        customSystemPrompt,
      )
      setBotIsTyping(true)
      const updateAssistantAnswer = (updateFn: (msg: Message) => Message) => {
        setConversation((conversation) => {
          const last = conversation[conversation.length - 1]
          if (last.role === 'assistant') {
            return [...conversation.slice(0, -1), updateFn(last)]
          } else {
            const newMessage: Message = {
              role: 'assistant',
              text: '',
              steps: [],
              documents: [],
              citations: [],
            }
            return [...conversation, updateFn(newMessage)]
          }
        })
      }
      for await (const chunk of responseStream) {
        if (chunk.answer) {
          updateAssistantAnswer((last) => ({
            ...last,
            text: last.text + chunk.answer,
          }))
        }
        if (chunk.documents) {
          updateAssistantAnswer((last) => ({
            ...last,
            // Find the step with the same runId, and append documents to it:
            steps: (last.steps || []).map((step) => {
              if (step.runId === chunk.runId) {
                return { ...step, documents: chunk.documents }
              } else {
                return step
              }
            }),
            documents: [...(last.documents || []), ...chunk.documents],
          }))
        }
        if (chunk.citations) {
          updateAssistantAnswer((last) => ({
            ...last,
            citations: [...(last.citations || []), ...chunk.citations],
          }))
        }
        if (chunk.facts) {
          updateAssistantAnswer((last) => ({
            ...last,
            facts: [...(last.facts || []), ...chunk.facts],
          }))
        }
        if (chunk.removedDocumentIds) {
          updateAssistantAnswer((last) => ({
            ...last,
            documents: (last.documents || []).filter(
              (doc) => !chunk.removedDocumentIds.includes(doc.id),
            ),
          }))
        }
        if (chunk.steps) {
          updateAssistantAnswer((last) => ({
            ...last,
            steps: [...(last.steps || []), ...chunk.steps],
          }))
        }
      }
      setBotIsTyping(false)
    }
  }

  const fillQuestionnaireX = async (text: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return ''
    }
    const response = await fillQuestionnaire(
      token,
      customerConfig.chatServer,
      text,
    )
    return response
  }

  const submitQuestionnaireX = async (answers: any) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const response = await submitQuestionnaire(
      token,
      customerConfig.chatServer,
      answers,
    )
    return response
  }

  const conversationEmpty = conversation.length === 0
  const waitingForBotToRespond =
    conversation.length > 0 &&
    conversation[conversation.length - 1].role === 'user'

  return (
    <div
      className={`h-screen ${darkMode ? 'dark' : ''} flex ${customerConfig.theme.isLight ? 'bg-white dark:bg-gray-900 dark:text-white' : customerConfig.theme.bgColor}`}
    >
      <Sidebar
        activeTab={activeTab}
        availableTabs={customerConfig.tabsInSidebar ? availableTabs : []}
        setActiveTab={setActiveTab}
        isOpen={sidebarOpen}
        extraChildren={
          <div className={'flex flex-col justify-center gap-2 p-1'}>
            {!conversationEmpty && (
              <>
                <Button
                  onClick={startNewConversationThread}
                  variant={'primary'}
                >
                  <AddCommentIcon />
                  Ny samtale
                </Button>
                <GiveFeedback onSubmit={onFeedbackSubmit} />
              </>
            )}
            {accounts && accounts.length > 0 && (
              <>
                <FeedbackExplorer
                  getToken={() =>
                    getAccessTokenForSemanticApi(accounts, instance)
                  }
                />
                <Button onClick={toggleDarkMode}>
                  <Brightness4Icon />
                  Dark mode
                </Button>
                {me && me.canUseCustomSystemPrompt && (
                  <CustomSystemPromptDialog
                    customSystemPrompt={customSystemPrompt}
                    setCustomSystemPrompt={setCustomSystemPrompt}
                    me={me}
                  />
                )}
              </>
            )}
          </div>
        }
      />
      <div
        className={
          'flex flex-col  ' +
          // (customerConfig.theme.isLight ? 'bg-white' : customerConfig.theme.bgColor) +
          ' ' +
          (sidebarOpen ? 'flex-1' : 'flex-1')
        }
      >
        <nav
          className={`flex justify-between items-center p-3 ${customerConfig.theme.isLight ? 'text-blue-600 dark:text-blue-300' : 'text-white'}`}
        >
          <div>
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className={'cursor-pointer lg:hidden'}
            >
              <MenuIcon />
            </button>
          </div>
          {/*<img src={customerConfig.logo} className="h-14 mb-4" alt="logo" />*/}
          <img
            src={
              customerConfig.theme.isLight && !darkMode
                ? 'semantic_lab_logo_farget.png'
                : 'semantic_lab_logo_hvit.png'
            }
            className="h-8"
            alt="logo"
          />
          <button
            onClick={startNewConversationThread}
            className={'cursor-pointer'}
          >
            <MapsUgcIcon />
          </button>
        </nav>
        {activeTab === 'statistikk' && questionnaireFields ? (
          <Questionnaire
            fields={questionnaireFields}
            fillQuestionnaire={fillQuestionnaireX}
            submitQuestionnaire={submitQuestionnaireX}
          />
        ) : (
          <Chat
            conversation={conversation}
            emptyConversationPlaceholder={
              <div
                className={
                  'flex-1 flex gap-4 flex-col items-center text-xl text-gray-500'
                }
              >
                <h1
                  className={`text-4xl ${customerConfig.theme.textColor} font-bold dark:text-white`}
                >
                  Velkommen til assistenten!
                </h1>
                <p className={'text-lg'}>
                  Still et spørsmål for å starte en samtale.
                </p>
              </div>
            }
          />
        )}

        <div className="p-3 bg-gray-100 dark:bg-gray-800">
          <ChatInputBox
            onSubmit={onSubmitChatMessage}
            quickTextActions={
              conversationEmpty ? [] : customerConfig.quickTextActions
            }
            canSubmit={
              !waitingForBotToRespond && !botIsTyping && threadId !== null
            }
          />
        </div>
      </div>
    </div>
  )
}

export default App
