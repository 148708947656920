import { useMsal } from '@azure/msal-react'
import UserIcon from '@mui/icons-material/AccountCircle'
import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'

const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div
      className="flex items-center gap-2 flex-col p-4"
      tabIndex={0}
      role={'button'}
      onClick={handleLogin}
    >
      <UserIcon style={{ fontSize: 40 }} />
      {accounts && accounts.length ? (
        <span>{accounts[0].name}</span>
      ) : (
        <span>Logg in</span>
      )}
    </div>
  )
}

export const Sidebar = ({
  extraChildren,
  isOpen,
  availableTabs,
  activeTab,
  setActiveTab,
}: {
  extraChildren?: React.ReactNode
  isOpen: boolean
  availableTabs: Tab[]
  activeTab: string
  setActiveTab: (tab: string) => void
}) => {
  const customerConfig = useCustomerConfig()

  return (
    <header
      className={`${customerConfig.theme.isLight ? 'bg-white text-black dark:bg-gray-900 dark:text-white' : customerConfig.theme.bgColor + ' text-white'} h-full flex flex-col items-center justify-between ${isOpen ? 'w-60 p-4' : 'w-0 p-0 overflow-hidden'} lg:w-60 lg:p-4 transition-all duration-500 ease-in-out`}
    >
      <div
        className={`flex flex-col items-center rounded-xl text-center gap-4 mt-16`}
      >
        <h1 className="text-2xl font-bold m-0">{customerConfig.header}</h1>
        <span>{customerConfig.subheader}</span>
      </div>
      <Tabs
        activeTab={activeTab}
        availableTabs={availableTabs}
        setSelectedTab={setActiveTab}
      />
      <div>
        <UserIconAndName />
        {extraChildren}
      </div>
    </header>
  )
}

export type Tab = {
  id: string
  label: string
  icon?: React.ReactNode
}

type TabProps = {
  activeTab: string
  availableTabs: Tab[]
  setSelectedTab: (tab: string) => void
}

const Tabs = (props: TabProps) => {
  return (
    <nav className="flex flex-col gap-2 mt-4">
      {props.availableTabs.map((tab) => (
        <TabButton
          key={tab.id}
          active={props.activeTab === tab.id}
          onClick={() => props.setSelectedTab(tab.id)}
        >
          {tab.icon}
          {tab.label}
        </TabButton>
      ))}
    </nav>
  )
}

const TabButton = ({
  children,
  active,
  onClick,
}: {
  children: React.ReactNode
  active: boolean
  onClick: () => void
}) => {
  return (
    <button
      onClick={onClick}
      className={`p-2 flex gap-2 w-full rounded-lg border-2 border-transparent focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md dark:border-gray-700 dark:border-2 dark:bg-gray-700 dark:text-white ${active ? 'border-blue-500 bg-gray-100 dark:bg-gray-600 dark:border-blue-300' : 'bg-gray-50 dark:bg-gray-800'}`}
    >
      {children}
    </button>
  )
}
