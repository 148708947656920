import React from 'react'
import useCustomerConfig from '../hooks/useCustomerConfig'

type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'normal'
  highlighted?: boolean
}

export const Button = ({
  onClick,
  children,
  size,
  variant,
  highlighted,
}: ButtonProps) => {
  const customerConfig = useCustomerConfig()
  const extraClasses =
    (size === 'small' ? 'p-1 text-sm' : size === 'large' ? 'p-3' : 'p-2') +
    ' ' +
    (variant === 'primary'
      ? ` ${customerConfig.theme.isLight ? customerConfig.theme.bgColor : 'bg-black/20'} text-white hover:bg-opacity-100 dark:border-gray-700 dark:text-white dark:hover:bg-opacity-100`
      : ` bg-gray-200 dark:bg-gray-800 bg-opacity-80 text-black dark:text-white hover:bg-opacity-90 ${highlighted ? 'border-blue-500 border-2' : 'border-gray-300 dark:border-gray-700'}`)

  return (
    <button
      type={'button'}
      onClick={onClick}
      className={
        'inline-flex gap-2 items-center p-1 rounded-lg border transform transition-transform duration-150 ' +
        extraClasses +
        ' hover:scale-105 active:scale-95'
      }
    >
      {children}
    </button>
  )
}
