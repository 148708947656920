import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: 'b30c21c8-8f86-482c-8da9-8dcbdb7a87bd',
  header: 'RUSinfo',
  subheader: 'Alfa 6.0',
  // logo: 'drugchat.jpg',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    isLight: true,
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    bubbles: 'bg-green-600',
  },
  chatServer:
    (process.env.REACT_APP_CHAT_SERVER || 'https://ai.semanticlab.no') +
    '/api/v1/chatbots/rusinfo',
  quickTextActions: ['Lag teksten kortere', 'Lag teksten mer lettlest'],
  tabsInSidebar: true,
}
export default config
