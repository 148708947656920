import React, { useEffect, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'

type DialogProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  header: string
}

export const Dialog = ({ isOpen, onClose, children, header }: DialogProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal()
    } else {
      dialogRef.current?.close()
    }
  }, [isOpen])

  return (
    <dialog
      ref={dialogRef}
      className={
        'fixed inset-0 p-4 bg-white dark:bg-gray-700 dark:shadow-gray-900 dark:text-white shadow-lg rounded-lg max-w-6xl w-full z-50 m-auto ' +
        (isOpen ? '' : 'hidden')
      }
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl">{header}</h2>
        <button
          onClick={onClose}
          className="p-2 rounded-full bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 focus:outline-none"
        >
          <CloseIcon />
        </button>
      </div>
      <section className="overflow-y-auto max-h-[70vh]">{children}</section>
    </dialog>
  )
}
